import React from 'react';
import '../styles/blog.css';

function Blog() {
  return (
    <div className="Blog">
      <h1>Blog</h1>
    </div>
  );
}

export default Blog;