import React from 'react';
import '../styles/1111.css';

function MakenaBirthday() {
    return (
      <div className="secretpage">
        <div className="secretpagecontent">
        <h1>Happy Birthday Makena!!</h1>
          <p>
            Dates: 12/14 - 12/17 <br/>
            Location: Divide, CO <br/>
            Below are many options for things we can do day-to-day. The only set in stone items we have
            are 2 dinner reservations and a surprise before dinner on 12/16.
            <br/>
            <br/>
            12/14
            <ul>
              <li>4pm AirBnB Check-in</li>
              <li>Settle in, relax</li>
              <ul>
                <li>
                  Option: <a href="https://fac.coloradocollege.edu/museum/museum-free-day/" target="_blank" rel="noopener noreferrer">Free museum day at the Colorado Springs Center of Fine Arts
                  </a>
                </li>
              </ul>
            </ul>
            12/15
            <ul>
              <li>
                Leaving this open for your input. I would suggest that we challenge ourselves here,
                the more exhausted we are, the more fun the surprise activity is likely to be!<br/>
              </li>
                  Options:<br/>
                  <ul>
                    <li>
                    <a href="https://gardenofgods.com/" target="_blank" rel="noopener noreferrer">Garden of the Gods</a><br/>Paved, No set distance<br/>Easy
                    </li>
                    <li><a href="https://www.alltrails.com/trail/us/colorado/the-crags-trail--5" target="_blank" rel="noopener noreferrer">The Crags</a><br/>4.9 miles, moderate</li>
                    <li><a href="https://www.alltrails.com/trail/us/colorado/raspberry-mountain-trail-via-ring-the-peak-trail" target="_blank" rel="noopener noreferrer">Raspberry Mountain Trail via Ring the Peak Trail</a><br/>4.6 miles<br/>Moderate</li>
                  </ul>
                  <li>4:00 - 6:30pm, Surprise Activity</li>
                  <li>7:00pm Dinner Reservation at [REDACTED]</li>
                    {/* <a href="https://caspiancafe.com/" target="_blank" rel="noopener noreferrer">Caspian Cafe</a></li> */}
              </ul>
              12/16
              <ul>
                <li>Explore Colorado Springs<br/>Options:</li>
                <ul>
                  <li>Drop in yoga class</li>
                  <ul>
                    <li><a href="https://www.praxisyoga.com/" target="_blank" rel="noopener noreferrer">Praxis Yoga</a></li>
                    <li><a href="https://www.cambioyoga.com/" target="_blank" rel="noopener noreferrer">Cambio Yoga</a></li>
                  </ul>
                  <li>Window Shopping</li>
                  <ul>
                    <li><a href="https://www.thepromenadeshopsatbriargate.com/" target="_blank" rel="noopener noreferrer">The Promenade Shops at Briargate</a></li>
                    <ul>
                      <li>Outdoor Shopping Centre</li>
                    </ul>
                    <li><a href="https://www.firstandmaintowncenter.com/" target="_blank" rel="noopener noreferrer">First & Main Town Centre</a></li>
                  <ul>
                    <li>Indoor Shopping Centre</li>
                    <li>Movie Theatre</li>
                  </ul>
                  </ul>
                </ul>
                <li>7:00pm Dinner Reservation at <a href="https://www.rabbitholedinner.com/" target="_blank" rel="noopener noreferrer">The Rabbithole</a></li>
              </ul>
              12/17
              <ul>
                <li>11am AirBnB Check-out</li>
              </ul>
          </p>
        </div>
      </div>
    );
  }

export default MakenaBirthday;