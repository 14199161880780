import React from 'react';
import '../styles/home.css';

function Home() {
  return (
  <div className="Home">
    <div className="HomeContent">
    <h1>Matt Krieger</h1>
  <p>Data Engineer</p>
    </div>
  </div>
  );
}

export default Home;