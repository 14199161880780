import React from 'react';
import '../styles/contact.css';

function Contact() {
  return (
    <div className="Contact">
      <h1>How To Contact Me</h1>
    </div>
  );
}

export default Contact;